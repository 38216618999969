.modal-header {
    background: #436db2;
}

.modal-title {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
}

.modal-btn-close {
    color: #fff !important;
    font-size: 30px;
    cursor: pointer;
}

.eventModal-info {
    margin: 10px 0px 20px 0px;
}

.eventModal-info-heading {
    /* font-size: 17px; */
    margin-bottom: 0px;
    font-weight: bold;
    color: #286b9a;
}

.eventModal-info-paras {
    margin-bottom: 4px;
}

.eventModal-info-paras p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.eventModal-info-ol li {
    color: #888;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
}

.modal-dialog-scrollable {
    height: calc(100% - 3.5rem) !important;
}


.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, .125);
}