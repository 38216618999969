.MembershipPage {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  padding: 10px;
  justify-content: space-around;
  /* For a card-like css */
  border-radius: 20px;
  z-index: 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgba(38, 38, 38, 0.2);
}

.MembershipPage .card-image {
  margin: 10px;
  width: 40%;
}

.MembershipPage .card-image img {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

.MembershipPage .card-text {
  text-align: left;
  flex: 0 1 50%;
  padding: 15px;
  margin: 10px;
  font-size: 17px;
}

.membership-banner {
  color: #2a405f;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 30%;
  right: 60%;
  left: 40%;
  bottom: 70%;
}

@media screen and (max-width: 700px) {
  .MembershipPage {
    flex-direction: column;
    padding: 0px 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  }

  .MembershipPage .card-image {
    margin: auto;
    width: 100%;
  }

  .MembershipPage .card-text {
    text-align: center;
    flex: 0 1 50%;
    margin: 10px;
    font-size: 14px;
  }

  .membership-banner {
    font-size: 30px;
    top: 30%;
    right: 0%;
    left: 0%;
    bottom: 70%;
    
  }
}
