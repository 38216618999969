.aic-work-sec {
    margin-top: 20vh;
    background-color: rgb(255, 204, 0);
    padding-top: 15vh;
    padding-bottom: 15vh;
    color: #314c70;
}

.aic-work-sec .card-list-container {
    display: flex;
    width:100vw;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.aic-work-sec .card-list-container .aic-work-card-container {
    flex:1;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.aic-work-sec .card-list-container .aic-work-card {
    display: flex;
    flex: 1;
    justify-content: center;
}

.aic-work-sec .aic-work-card .text-container p {
    color: white !important;
}

.aic-work-sec .aic-work-card .img-container img {
    background-color: white;
    object-fit: contain;
    padding: 10px;
}

.aic-work-sec .card-circle{
    box-shadow: 15px 20px 12px 8px rgba(0, 0, 0, 0.6);
}

@media only screen and (orientation: portrait), (max-width: 500px){

    .aic-work-sec {
        margin-top: 0;
        background-color: white;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }

    .aic-work-sec .card-list-container{
        display: flex;
        flex-direction: column;        
    }

    .aic-work-sec .aic-work-card-container {
        flex-grow: 1;
        align-self: stretch;
        padding-bottom: 0;
    }

    .aic-work-sec .aic-work-card .img-container img {
        padding: 0;
    }

    
}