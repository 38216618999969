/* For Projects Page */

.projectsCard .cards-container {
  margin: 30px 10px 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projects-banner {
  position: absolute;
  font-weight: bold;
  color: #2a405f;
  text-align: center;
  left: 0;
  right: 0;
  top: 20%;
}

.projectsCard .cards {
  flex: 0 1 10%;
  display: flex;
  flex-direction: column;
  top: 0px;
  position: relative;
  max-width: 262px;
  height: 500px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.projectsCard .cards:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
}

.projectsCard .cards:hover:before {
  transform: scale(2.15);
}

.projectsCard .cards-image img {
  width: 90%;
}

/* .projectsCard .cards-image img:hover {
  width: 100%;
} */

.projectsCard .cards-text {
  margin: 20px 2px 2px 2px;
  text-align: center;
}

.cards-text Link{
  text-decoration: none;
}

/* For Project Page */

.projectCard .card-container {
  display: flex;
  justify-content: space-around;
  top: 0px;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.projectCard  .card-image{
  flex-basis: 30%;
}

.projectCard  .card-image img{
  /* width: 90%; */
}

.projectCard  .card-text{
  margin:15px;
  padding: 17px;
  flex-basis: 70%;
  text-align: left;
}

/* For Responsiveness */

@media screen and (max-width: 992px) {
  .banner {
    height: 40vh;
  }

  .projectCard  .cards-container {
    flex-wrap: wrap;
  }

  .projectCard  .card-container {
      flex-wrap: wrap;
    }
}

@media screen and (max-width: 600px) {

  /* For Projects Page */
  .projectCard  .cards-container{
    margin: auto;
  }
  .projectCard  .cards {
    margin: 20px;
  }
}