.event-page-card-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 35px 0px;
    gap: 2.5rem;
    font-family: "Roboto", sans-serif;
    flex-wrap: wrap;
}


.event-page-card {
    display: flex;
    flex-direction: column;
    width: 250px;
    /* height: 30rem; */
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
    background-color: #cce2eea1;
    color: #0000007a;
    gap: 15px;
    padding: 20px 23px 20px 23px;
}


.event-page-card-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.event-page-card-card-content-heading-div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.event-page-card-card-content-heading-icon {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
}

.event-page-card-card-content h2 {
    font-size: 23px;
    text-transform: capitalize;
    font-weight: bold;
    color: #286b9a;
    margin-bottom: 0px;
}

.event-page-card-card-content p {
    color: #888;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
    /* text-align: center; */
}

.event-page-card:hover {
    /* background: #2a405f; */
    background: #436db2;
    color: #fff !important;
}

.event-page-card:hover .event-page-card-card-content h2,
.event-page-card:hover .event-page-card-card-content p {
    color: #fff;
}