nav {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  /* position: fixed; */
  width: 100vw;
  /* z-index: 200; */
  transition: 0.5s;
  /* border-bottom: 1px solid rgb(121, 121, 121); */
  padding: 0px !important;
}
.navbar{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  
}
.nav-color{
  background-color: white;
}


nav > * {
  flex-direction: row;
}

.logo_container {
  display: flex;
  /* border:1px solid black; */
  /* color: #44699d; */
  margin-left: 35px;
  padding: 5px;
}

.logo_container a {
  font-size: 2.5vw;
  font-weight: bold;
}

.logo_container img {
  object-fit: contain;
  height: 4vw;
  margin-right: 5px;
}

.list_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  list-style: none;
  align-items: stretch;
  align-self: stretch;
  padding-right:2%;
}

.list_container li {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  /* transition: all 5s; */
}

.list_container li:hover > *:not(.dropdown_container *) {
  color: white !important;
  /* background-color: "#3e92f2"; */
  /* background-color: #3e92f2; */
  background:#3e92f2;
}

.list_container li > *:not(.dropdown_container *) {
  /* color: #44699d; */
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  border-radius:5px ;

  /* border: 1px solid rgb(255, 255, 255); */
  transition: 0.3s;
}

.search_form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.search_form input {
  outline: none;
  border-radius: 50px 0 0 50px;
  padding-left: 10px;
  height: auto;
  font-size: 14px;
  border: 1.5px solid rgb(179, 179, 179);
  border-right: 0px;
}

.search_form button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  box-shadow: none;
  margin: 0;
  margin-right: 20px;
  background-color: rgb(179, 179, 179);
  transition: 0.3s;
  width: 3vw;
  height: min-content;
  border-radius: 0 500px 500px 0;
}

.search_form button:hover {
  background-color: #44699d !important;
  color: white !important;
}


.list_container li > *:hover + .dropdown_container {
  transform: scaleY(1);
  transition: transform 0.8s;
}

.dropdown_container:hover {
  transform: scaleY(1);
}

.dropdown_container li:hover {
  background-color:#3e92f2 !important;
}

.dropdown_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  z-index: 500;
  width: 8vw;
  /* height: 15vh; */
  margin-top:5px ;
  padding: 15px 10px 15px 10px !important;
  top: 100%;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform 0s;
  background-color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-top: 0px;
  border-radius: 0 0 10px 10px;
}

.dropdown_container li {
  padding: 1vh 0vh !important;
  width: 100%;
  border-radius: 5px;
  
}

.dropdown_container li > * {
  color: #44699d;

}

.dropdown_container li:hover > * {
  color: white !important;
}
.drawerBtn {
  outline: none;
  overflow: hidden;
  display: none !important;
  border: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  position: relative;
  color:#3e92f2 !important;
}

.drawerBtn:focus {
  border: 1px solid black;
}

@media only screen and (max-width:999px) {
  .list_container {
    padding:0%;
    width: fit-content;
  }
  .list_container li {
    font-size: 5px!important;
  }
  .logo_container {
    margin-left: 0px;
    padding: 0px !important;
  }
  .logo_container a {
    font-size: 2.3vw !important;
    margin-top: 5px;
  }
}
@media only screen and (max-width:779px) {
  /* nav {
    padding: 0px 20px;
    padding-top: 0px!important;
    align-items: center;
    flex-direction: row-reverse;
  } */
  .navbar{
    padding-top: 5px!important ;
    padding-bottom: 5px!important ;
    padding-left: 30px!important ;
    padding-right: 20px!important ;
  }
  .logo_container {
    flex-direction: row-reverse !important;
  }
  .logo_container a {
    font-size: 25px !important;
  }
  .list_container {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
  .drawerBtn {
    display: flex !important;
    
  }
  .drawerBtn i {
    color:#3e92f2;
  }
}
@media only screen and (max-width:576px){
  .nav-color{
    background-color:rgb(2,51,84);
  }

  .logo_container img {
    height: 5vh;
    margin: 0px;
  }

  .logo_container .navbar-brand{
    display: none;
  }

  .drawerBtn i {
    color: white; 
  }
}
/* @media (max-width:769px){
  nav {
    padding: 0 20px;
    align-items: center;
    flex-direction: row-reverse;
  }
  .logo_container img {
    width: 40;
    height: 40;
  }
  .logo_container {
    flex-direction: row-reverse;
  }
  .logo_container h2 {
    font-size: 14px;
  }
  .logo_container img {
    height: 40px;
  }
  .list_container {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
  .search_form {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }

  .drawerBtn {
    display: flex;
    background-color:"none"!important;
  }
  .drawerBtn i{
    color: #3e92f2 !important;
  }
}
@media only screen and (max-width: 920px) and (min-width: 769px) {
  .list_container li > * {
    font-size: 1.2vw !important;
  }

  .logo_container,
  .logo_container h2 {
    width: 0px !important;
    font-size: 0px !important;
    height: 0px !important;
  }

  .logo_container img {
    width: 0;
    height: 0;
  }
  /* .drawerBtn {
    width: 0;
    height: 0;
    outline: none;
    overflow: hidden;
    display: none;
  } }*/

/* @media only screen and (orientation: portrait) and (max-width: 600px) { */
  /* nav {
    padding: 0 20px;
    align-items: center;
    flex-direction: row-reverse;
  }
  .logo_container {
    flex-direction: row-reverse;
  }
  .logo_container h2 {
    font-size: 14px;
  }
  .logo_container img {
    height: 40px;
  }
  .list_container {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
  .search_form {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }

  .drawerBtn {
    display: flex;
    width: 10vw;
    height: 10vw;
    border-radius: 8px;
  }} */
/* @media (max-width:576px) {
  .nav-color{
    background-color: rgb(2,51,84);
  }
} */