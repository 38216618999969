.overlay_open {
  width: 0;
  height: 0;
  overflow: hidden;
}

.drawer-routeList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
}
.drawer-routeList a{
  text-decoration: none;
}

.routeItem-container{
  display: flex;
  flex-direction:column;
  align-items: stretch;
  /* border-bottom: 0.5px solid rgb(182, 182, 182); */
  margin: 0px 8px;
}
.routeItem {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  list-style-type: none;
  transition: all 0.2s;
  border-radius: 5px;
}
.routeItem:hover{
  background-color: rgba(204, 232, 255, 0.5);
  color: #0275d8;
}
.Link{
  width: 100%;
  padding: 10px;
  color:grey;
  text-align: left;
  white-space: nowrap;
  border-radius:5px;
}
.drawer_container_close {
  overflow-x: hidden;
  position: fixed;
  width: 0vw;
  height: 100vh;
  background-color: white;
  z-index: 2000;
  transition: width 0.5s;
}

.dropdownRoutes {
  flex-direction: column;
  align-items: stretch;
  width: 91%;
  justify-content: center;
  margin-left: 10%;
  animation: growOut 400ms ease-in-out forwards;
  transform-origin: top center;
}
@keyframes growOut {
  0% {
     transform: scale(0)
 }
 80% {
     transform: scale(1.1)
 }
 100% {
     transform: scale(1)
 }
}
.dropdownItems{
    padding: 10px;
    text-align: left;
    white-space: nowrap;
    border-radius: 5px;
    color:grey;
}
.dropdownItems:hover{
  background-color: rgba(204, 232, 255, 0.5);
}
.dropdownClose {
  height: 0%;
  display: none;
  overflow: hidden;
}

.dropdownOpen {
  height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  background-color: white;
}

.dropdownOpen li {
  border-bottom: 0.5px solid rgb(182, 182, 182);
}

@media only screen and (orientation: portrait), (max-width: 500px) {
  .overlay_closed {
    width: 0;
    height: 0;
  }
  .overlay_open {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .drawer_container_open {
    width: 70vw;
    height: 100vh;
    background-color: white;
    border-right: 1px solid rgb(82, 82, 82);

  }
}
