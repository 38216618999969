/* .footer_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  padding: 5vh 1vw;
  /* background: url(../../images/map-world.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
}
a:link {
  text-decoration: none;
}

.footer_container h1 {
  font-size: 24px;
}

.contact_info ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

footer {
  background-color: #fafafa;
  padding: 15px;
  font-weight: bold;
  font-size: small;
}

.sub_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 12px;
  align-items: center;
  border:2px solid rgb(255, 245, 245);
}

.sub_container ul {
  width: 80%;
}

.sub_container li {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.sub_container i {
  width: 40px;
  padding-right: 5px;
}

.logo_aic {
  object-fit: contain;
  position: relative;
  width: 50%;
  justify-self: flex-start;
}

.round_btns_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.round_btn {
  background-color: #44699d;
  position: relative;
  border-radius: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin: 0 10px;
  transition: 0.3s;
}

.round_btn:hover {
  background-color: #0b2242;
}

.round_btn i {
  color: white;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  padding: 0;
}

.imp_links li {
  padding-top: 5px;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.imp_links i {
  color: #44699d;
}

.imp_links div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imp_links a {
  text-decoration: none;
  color: black;
}
.links{
  text-align: center !important;
}
@media only screen and (max-width:769px){
  .links{
    text-align: start !important;
  }
  .footer{
    padding-top:0px !important;
  }
  .footer .container{
    padding-top:0px !important;

  }
  .round_btns_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
}
/* @media only screen and (orientation: portrait), (max-width: 700px) {
  .footer_container {
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .logo_aic {
    width: 40vw;
    height: 40vw;
  }
  .sub_container {
    width: 90vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  .sub_container ul {
    padding: 10px 10px;
    text-align: left;
  }

  .sub_container li {
    flex-direction: row;
  }
} */
.footer {
  position: relative;
  padding-top: 45px;
  background: #000000;
}

.footer .footer-about,
.footer .footer-contact,
.footer .footer-links,
.footer .footer-project {
  position: relative;
  margin-bottom: 10px;
  color: #999999;
}
.footer .footer-about {
  display: flex;
  flex-direction: column;
}
.footer .footer-about h4,
.footer .footer-contact h4,
.footer .footer-links h4,
.footer .footer-project h4 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  color: #eeeeee;
}

.footer .footer-about h4::after,
.footer .footer-contact h4::after,
.footer .footer-links h4::after,
.footer .footer-project h4::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #eeeeee;
}
.footer .footer-contact p {
  margin-bottom: 10px;
  color: #999999;
}

.footer .footer-contact a .icon {
  color: #999999;
}

.footer .footer-contact a:last-child .icon {
  margin: 0;
  margin-right: 5px;
}

.footer .footer-contact a:hover,
.footer .footer-contact a:hover .icon {
  color: #0085ff;
}
.footer .footer-contact a {
  margin-bottom: 6px;
  padding-left: 15px;
  color: #999999;
  display: block;
  text-decoration: none;
}
.footer .footer-links a {
  margin-bottom: 6px;
  padding-left: 15px;
  color: #999999;
  display: block;
  text-decoration: none;
}

.footer .footer-links a:last-child {
  margin: 0;
}

.footer .footer-links a:hover {
  color: #0085ff;
}

/* .footer .footer-links a::before {
  position: absolute;
  content: "\F285";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
} */
/* .footer .footer-project {
  float: left;
  font-size: 0;
} */
.round_btns_container {
  display: flex;
}
.round_btn {
  background-color: #44699d;
  position: relative;
  border-radius: 50vw;
  display: flex;
  flex-direction: row;
  /* border: 1px solid white; */
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  margin: 0 10px;
  transition: 0.3s;
}

.round_btn:hover {
  background-color: #0b2242;
  color: white;
}

.round_btn i {
  color: rgb(18, 18, 18);
  position: relative;
  top: 0;
  bottom: 0;
  font-size: larger;
  right: 0;
  left: 0;
  text-align: center;
  padding: 0;
  transition: 0.3s;
}
.round_btn i:hover {
  color: white;
}
.footer .copyright {
  position: relative;
  padding: 10px 0;
  background: #000000;
}

.footer .copyright .copy-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .footer .copyright {
    padding: 0px 0;
  }
  .footer .copyright .copy-text,
  .footer .copyright .copy-menu {
    text-align: center;
  }

  .footer .copyright {
    padding-bottom: 15px;
  }
}
