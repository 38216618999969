.ourTeams-banner {
  position: absolute;
  text-align: center;
  top: 30%;
  bottom: 0;
  right: 0%;
  left: 0%;
  overflow: visible;
  font-weight: bold;
}

.our-teams-page .sec-2 .images .image-container {
  overflow: hidden;
  border: 1px solid white;
}

.our-teams-page .sec-2 .images .image-container img {
  object-fit: cover;
  object-position: top;
}

@media only screen and (orientation: portrait), (max-width: 500px) {
  .our-teams-page .sec-2 .images .image-container {
    height: auto;
  }
}
