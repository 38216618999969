.aboutUs-sec {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
}

.aboutUs-sec .aboutUs-card {
  background-color: #2a405f;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  width: 30vw;
  display: flex;
  margin: 10% 1%;
  position: relative;
  z-index: 500 !important;
  flex-grow: 1;
}

.aboutUs-sec .text-container,
.aboutUs-sec .text-container p {
  color: white !important;
  font-size: small;
  padding: 0px 12px;
}

.aboutUs-sec .text-container h3 {
  font-weight: bold;
  color: rgb(255, 200, 0);
}

.aboutUs-sec .text-container {
  text-align: center;
  padding-top: 6vw;
  padding-left: 10px;
  padding-right: 10px;
}

.aboutUs-sec .img-container {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    background-color: rgb(34, 125, 205);
    border: 10px solid rgb(255, 200, 0);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
    top: -5vw;
    right: 0px;
    left: 0px;
    z-index: -7000;
  }

  .aboutUs-sec .img-container img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .aboutUs-sec .img-container .our-mission {
    position: absolute;
    left: 6%;
    bottom: 7%;
  }

  .aboutUs-sec .img-container .our-vision {
    object-fit: cover !important;
  }

@media only screen and (orientation: portrait), (max-width: 500px) {
  .aboutUs-sec {
    flex-direction: column;
    background: white;
    padding-top: 20vw;
    align-items: center;
  }
  .aboutUs-sec .aboutUs-card {
    background-color: #2a405f;
    width: 85vw;
    margin: 5% 5%;
    padding: 5px 10px;
    margin-bottom: 20vw;
  }

  .aboutUs-sec .text-container {
    margin-top: 20vw;
    padding: 0px 0px;

  }

  .aboutUs-sec .text-container,
  .aboutUs-sec .text-container p {
    color: white !important;
    font-size: small;
  }

  .aboutUs-sec .img-container {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    background-color: rgb(34, 125, 205);
    border: 10px solid rgb(255, 200, 0);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
    top: -20vw;
    right: 0px;
    left: 0px;
    z-index: -7000;
  }

  .aboutUs-sec .img-container img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .aboutUs-sec .img-container .our-mission {
    position: absolute;
    left: 2vw;
    bottom: 2vw;
  }

  .aboutUs-sec .img-container .our-vision {
    object-fit: cover !important;
  }
}
