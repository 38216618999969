.projects-sec {
  background: linear-gradient(white, transparent, white 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-sec .list-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    height: 50vh;
    width: 80vw;
    flex-grow: 1;
    margin-top: 15px;
}

.projects-sec .project-parent {
  flex-grow: 1;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}

.projects-sec .project-parent .project-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.4s;

}

.projects-sec .project-parent .project-card:hover{
    box-shadow: 0 0 8px 2px rgba(0, 61, 126, 0.7);
}

.projects-sec .project-parent .project-card .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
}



@media only screen and (orientation: portrait), (max-width: 500px) {
  .projects-sec {
    background: none;
    color: white;
    padding:0;
    overflow: hidden;
  }

  .projects-sec .header-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .projects-sec .header-container .text-container{
    margin: 0 5px;
  }

  .projects-sec .list-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
  }

  .projects-sec .project-parent {
    width: 90vw;
    margin-bottom: 8px;
  }
}
