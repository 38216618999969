.sec {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.imgSlide {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.sec1 {
  background-image: url("../../images/home/hero_banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  /* background-attachment: fixed; */
  /* max-width: 100%;
  height: auto; */
  margin: 0;
  padding: 0;
  border: none;
}
.sec1MoreInfo {
  background-image: url("../../../public/assets/DataCampWebBG.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  /* background-attachment: fixed; */
  /* max-width: 100%;
  height: auto; */
  margin: 0;
  padding: 0;
  border: none;
}
#herobtn {
  /* background-color: #44699d; */
  background-color: #3e92f2;
}
#herobtn:hover {
  background-color: #0064d7;
  /* color: #3e92f2!important; */
}
.sec2,
.sec3,
.sec4,
.sec5,
.sec6,
.sec7 {
  margin-bottom: 50px;
}

.sec4 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 0px;
}

.home .ourTeams-sec {
  padding-top: 10vh;
  padding-bottom: 10vh;
  background: linear-gradient(
    190deg,
    rgb(255, 238, 170) 30%,
    white 30%,
    white 70%,
    rgb(169, 205, 255) 70%
  );
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
}

.home .ourTeams-container {
  width: 100%;
  height: 100%;
}

.sec2 .card .h2 {
  margin-bottom: 20px;
}
.sec2 .container .card {
  width: 80%;
}
.sec3 {
  background-image: url("../../images/Project Background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.card-circle {
  text-align: center;
  color: white;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  margin: auto;
  padding: 40px;
  background-color: #045484;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.5s;
}
.card-circle:hover {
  background-color: #034771;
}
.doodle-img {
  width: 90px;
  height: 90px;
}
.sec6 {
  background-image: url("../../images/Blog Background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.about-us-cards {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.5s;
}
.about-us-cards:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.horizontal_card_image {
  /* background-position: center;
  background-size: cover; */
  background-color: black;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* background-repeat: no-repeat;
  height: 100%; */
}
.blog-card-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 32vh;
  border-radius: 5px;
  transition: all 0.5s;
}
.sponsor-card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.projects_card_image {
  background: none;
  border-radius: 10px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 55vh;
  transition: all 0.5s;
}
.projects_card_image:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.projects_card_image::after {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7));
  top: 0;
  border-radius: 10px !important;
  left: 0;
}

.homePage > h1 {
  font-size: 3vw;
}

.homePage > h2 {
  font-size: 2vw;
}

.homePage > h3 {
  font-size: 1.5vw;
}

.imgsec_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 80%;
}

.imgsec_div h1 {
  font-size: 3vw;
}

.h2 {
  font-size: 2vw;
}

.navSpace {
  height: 12vh;
}
.imageBg {
  flex: 1;
  display: flex;
  background-repeat: no-repeat;
  background-position: "center";
  background-size: "cover";
  height: "100%";
  width: "100%";
  justify-content: stretch;
  align-items: center;
  flex-direction: row;
  background-color: black;
}

.overlay {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  background: linear-gradient(
    45deg,
    #901aff80,
    #59129c80,
    #3b00dd4f,
    #3c70ff3d,
    #44699d6e
  );
  background-size: 600% 100%;
  animation: gradient 8s linear infinite;
  animation-direction: alternate;
  justify-content: flex-start;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.titlePara {
  color: white;
  text-align: left;
  border-radius: 10px;

  flex: 0.5;
  margin-left: 2vw;
  padding: 1%;
}

.joinUsBtn {
  outline: none;
  font-size: 1.5vw;
  border-radius: 6px;
  padding: 12px;
  color: white;
  font-weight: 2px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  background-color: #44699d;
}
.joinUsBtn:hover {
  background-color: #6f7ed3;
  color: black;
}
.joinUsBtn:focus {
  background-color: #1a2149 !important;
}

.blockA {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.border-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.border-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.imageBg2 {
  background-repeat: no-repeat;
  background-color: #160053;
  height: 100%;
  flex: 1;
  border-radius: 0% 10% 10% 0%;
  position: relative;
  overflow: hidden;
}

.typeBtn {
  border: none;
  background-color: inherit;
  padding: 0 2vw;
  color: #505050;
}

.typeBtn:hover {
  color: black;
  cursor: pointer;
}

.typeBtnFocus {
  color: #44699d;
}

.blockAB {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}

.projList {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.projectDiv {
  color: rgba(255, 255, 255, 0);
  height: 60vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: stretch;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.5s;
}

.projectDiv:hover {
  color: white;
}

.projectOverlay {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 3%;
}

.projectOverlay:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.image_slider {
  width: 45%;
}

.bgImgSec4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  margin: 0;
  opacity: 0.4;
}

.sec5_body {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}

.sec5_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  flex: 1;
}

.sec5_img {
  width: 100%;
  position: relative;
  animation-name: hoverAnimation2;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.sec6 {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding-bottom: 5%;
}

.bgImgSec6 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  margin: 0;
  opacity: 0.5;
}

.blogsList {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 5%;
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  border: 1px solid rgb(192, 192, 192);
  background-color: rgb(247, 247, 247);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 0 8px rgb(145, 145, 145);
}
.aboutus-card {
  background-color: #045484;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.blog:hover {
  box-shadow: 0 0 15px rgb(91, 91, 91);
}

.blog > * {
  width: 98%;
  font-size: small;
  border-radius: 10px;
}

.blog-1 {
  margin: 0px 20px;
}

.blog img {
  height: 180px;
}

.blog div {
  overflow-y: auto;
  align-self: center;
  margin: 10px;
  padding: 5px;
  height: 240px;
  background-color: rgb(233, 233, 233);
}

.carouselHome .carousel.carousel-slider .control-arrow {
  background-color: white;
  opacity: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  bottom: 50%;
  margin: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselHome .carousel .control-prev.control-arrow:before {
  border-right-color: rgb(165, 165, 165);
}

.carouselHome .carousel .control-next.control-arrow:before {
  border-left-color: rgb(165, 165, 165);
}
@keyframes hoverAnimation {
  0% {
    transform: translate(0%);
  }
  50% {
    transform: translate(2%, 1%);
  }
  100% {
    transform: translate(0%);
  }
}

@keyframes hoverAnimation2 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(0%);
  }
}

.aiRobot {
  position: relative;
  animation-name: hoverAnimation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}
/* @media only screen and (max-width:1025px){
  .horizontal_card_image div img{
    width: 280px;
  }
  .sec2 .container .card{
    width: 90%;
  }
}
@media only screen and (orientation: portrait), (max-width: 500px) {
  .sec_div h1 {
    font-size: 26px;
  }

  .homePage h1 {
    font-size: 26px !important;
    margin-bottom: 0px;
  }

  .homePage h2 {
    font-size: 18px !important;
  }

  .homePage h3 {
    font-size: 16px !important;
  }

  .homePage > p {
    font-size: 14px !important;
  }

  .overlay {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .titlePara {
    margin-left: 0;
    padding: 5%;
    text-align: center;
  }
  .joinUsBtn {
    font-size: 18px;
  }

  .navSpace {
    height: 10vw;
  }

  .blockA {
    flex-direction: column;
  }
  .imageBg2 {
    border-radius: 50% 50% 0 0;
    margin-bottom: 10px;
  }
  .aiRobot {
    height: 50vw;
    width: 50vw;
  }
  .blockAB {
    text-align: center;
  }
  .sec_div {
    width: 90vw;
  }

  .projList {
    flex-direction: column;
  }
  .projectDiv {
    color: white;
    padding: 0;
    justify-content: center;
    align-items: stretch;
    width: 100vw;
    height: 20vh;
    text-align: center;
  }
  .projectOverlay {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3%;
  }
  .sec4 {
    height: 100vh;
  }
  .image_slider {
    width: 100%;
  }
  .bgImgSec4 {
    transform: rotate(90deg);
    background-position: center;
    width: 100vh;
    height: 100%;
  }
  .sec5_body {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
  .imageContainer {
    flex: 0.5;
    position: relative;
  }
  .sec5_img {
    position: relative;
  }
  .listPrepare {
    padding: 12px;

    list-style-type: none;
    border: 1px solid white;
    border-radius: 20px;
    text-align: center;
    list-style: none;
  }

  .listPrepare li {
    text-align: center;
    font-size: 12px;
  }
  .sec6 {
    position: relative;
    align-items: center;
    line-height: normal;
    overflow-x: hidden;
  }
  .blogsList {
    position: relative;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 0 5%;
  }
  .blog {
    flex-direction: column;
    align-items: stretch;
    width: 90%;
    flex: 1;
    margin-top: 10px;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 20px;
    padding: 3%;
    box-shadow: 0 0 5px rgb(210, 210, 210);
  }

  .blog > * {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
  }

  .blog img {
    object-fit: contain;
    height: 20vh;
  }

  .sec6 {
    padding-bottom: 10%;
  }

  .sec6 img {
    object-fit: cover;
    align-self: stretch;
  }

  .sec6 h2,
  .sec6 p {
    font-size: small;
    margin: 4px;
  }

  .blog img {
    border-radius: 10px;
  }

  .blog div {
    padding-top: 10px;
    overflow-y: auto;
    height: 25vh;
  }
}
.blog-card-img{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 32vh;
  border-radius: 5px;
} */
@media only screen and (max-width: 1025px) {
  /* .card-circle{
    width:280px;
    height:280px;
    border-radius:50%;
    background-color:#045484;
  } */
  .horizontal_card_image div img {
    width: 280px;
  }
  .sec1 {
    background-image: url("../../images/home/hero_banner.jpg");
  }
  .sec2 .container .card {
    width: 90%;
  }
}
@media only screen and (max-width: 992px) {
  /* .card-circle{
    width:200px;
    height:250px;
    border-radius:50%;
    padding:0px ;
    background-color:#045484;
  } */
  /* .doodle-img{
    width:70px;
    height:70px
  } */
  /* .card-circle{
    width:250px;
    height:250px;
    border-radius:50%;
    background-color:#045484;
    padding:20px ;
  } */
  /* .card-circle{
    width:320px;
    height:320px;
    border-radius:50%;
    padding:40px ;
    background-color:#045484;
  } */
  .horizontal_card_image div img {
    width: 260px;
  }
  .sec2 .container .card {
    width: 100%;
  }
  .triple-picture {
    height: 65vh;
    width: 82%;
    margin-top: 20px;
  }
  .triple-picture::after {
    height: 65vh;
    width: 100%;
  }
  .triple-picture::before {
    height: 65vh;
    width: 100%;
  }
  .projects_card_image {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 769px) {
  .sec1 h1 {
    font-size: 30px !important;
  }
  .sec2 .container .card {
    width: 90%;
  }
  .sec1 p {
    font-size: 15px !important;
  }
  .aboutus-card {
    border-radius: 8px;
  }
  .sec1 .btn {
    width: 15vw !important;
    font-size: 15px !important;
  }
  .triple-picture {
    height: 55vh;
    width: 88%;
    margin-top: 20px;
  }
  .triple-picture::after {
    height: 55vh;
    width: 100%;
  }
  .triple-picture::before {
    height: 55vh;
    width: 100%;
  }
  .projects_card_image {
    background: none;
    border-radius: 10px !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 70%;
    height: 60vh;
  }
  /* .card-circle{
    
    border-radius:0%!important;
    background-color:#045484;
  } */
}
@media only screen and (max-width: 576px) {
  .triple-picture {
    width: 55%;
    margin-top: 0px;
    height: 70vh;
  }
  .sec2 .container .card {
    width: 85%;
  }
  .sec2 .card .h2 {
    margin-bottom: 10px;
  }
  .triple-picture::after {
    height: 70vh;
    width: 100%;
  }
  .triple-picture::before {
    height: 70vh;
    width: 100%;
  }
  .sec1 {
    text-align: center;
    color: white;
    background-image: none;
    background-color: rgb(2, 51, 84);
    height: fit-content;
    border: 1px solid rgb(2, 51, 84) !important;
    border: 1px solid transparent;
  }
  .sec3 {
    border: none;
  }
  .sec1 .btn {
    width: 20vw !important;
    font-size: 15px !important;
  }
  .projects_card_image {
    background: none;
    border-radius: 10px !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 75%;
    height: 60vh;
  }
  .card-circle {
    width: auto;
    height: auto;
    border-radius: 0%;
    padding: 0px;
    color: black !important;
    background-color: white !important;
    align-items: center;
    text-align: start;
    box-shadow: none !important;
    margin: 0;
    margin-right: 10px;
  }

  .card-circle .text-div .rounded-overlay {
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    height: 100%;
    width: 85px;
    top: 7px;
    position: absolute;
    background-color: rgb(255, 255, 255);
  }
  .card-circle .text-div {
    display: flex;
    position: relative;
    align-items: center;
  }
  .card-circle .text-div .text {
    background-color: #045484;
    margin-left: 60px;
    padding: 10px 10px 10px 0;
    padding-left: 35px;
  }
  .doodle-img {
    /* position: relative; */
    width: 70px;
    height: 70px;
  }
}
/* @media only screen and (max-width:567px){
  .card-circle .text-div .rounded-overlay{
    top: 5px;
    height: 100%;
    width: 85px;
    background-color: #000000;
  }
  .doodle-img{
    margin-top: 12px;
    width:70px;
    height:70px
  }
} */

@media only screen and (max-width: 500px) {
  .home .ourTeams-sec {
    background: linear-gradient(
      30deg,
      rgb(255, 238, 170) 30%,
      white 30%,
      white 70%,
      rgb(169, 205, 255) 70%
    );
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.4);
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 426px) {
  .sec1 {
    text-align: start;
    display: flex;
    align-items: center;
    height: 100vh;
    color: white;
    background-image: url("../../images/banners/Mobile Banner425.png");
  }
  .aboutus-card {
    box-shadow: none;
    background-color: none;
  }
  .sec2 .container .card {
    width: 100%;
  }
  .sec2 .card .h2 {
    margin-bottom: 0px;
  }
  .sec1 .row .order-sm-1 {
    padding-left: 40px;
  }
  .aboutus-card {
    padding: 0px !important;
    background-color: white;
  }
  .aboutus-card h4 {
    display: none;
  }
  .aboutus-card .btn {
    display: none;
  }
  .aboutus-card h4,
  p {
    /* background-color: none !important; */
    color: black;
  }

  .sec1 .row .order-sm-2 {
    display: none;
  }
  .sec1 .btn {
    width: auto !important;
    font-size: 15px !important;
  }
  .sec1 h1 {
    font-size: 28px !important;
  }
  .sec1 p {
    font-size: 19px !important;
  }
  /* .card-circle .text-div .rounded-overlay{
    border-radius: 50%!important;
    height: 100px;
    width: 85px;
    position:absolute;
    background-color: white;

  } */

  .card-circle .text-div .text {
    background-color: #045484;
    margin-left: 55px;
    padding-left: 35px;
  }
}
@media only screen and (max-width: 376px) {
  /* .card-circle .text-div .rounded-overlay{
    border-radius: 50%!important;
    height: 100px;
    width: 80px;
    position:absolute;
    background-color: white;

  } */
  /* .doodle-img{
    width:65px;
    height:65px
  } */
  .blog-card-img {
    height: 30vh;
  }
  .projects_card_image {
    height: 50vh;
    width: 80%;
  }
}
@media only screen and (max-width: 320px) {
  .projects_card_image {
    height: 50vh;
    width: 90%;
  }
  .blog-card-img {
    height: 24vh;
  }
}
/*@media (max-width:769px){
  .triple-picture{
    width: 80%;
    height: 65vh;
    position: relative;
    background-image: url("../../images/ourProjects1.jpg");
  }
  .triple-picture::after{
  
    content: " ";
    background-image: url("../../images/ourProjects4.jpg");
    position: absolute;
    height: 65vh;
    width: 100%;
    right: 30px;
    top: 30px;
  }
  .triple-picture::before{
    content: url("../../images/ourProjects2.jpg");
    background-image: url("../../images/ourProjects3.jpg");
    content: " ";
    position: absolute;
    height: 65vh;
    width: 100%;
    right: 15px;
    top: 15px;
  }
} */
