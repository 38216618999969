@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap');

/* all cards  */
.eventsPage .cards-container {
  margin: 30px 10px 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background: black; */
}

.eventsPage .cards {
  width: 20vw;
  height: 20vw;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #f2f8f9; */
  /* background-color: #3284be; */
  /* background-color: #72a6ca; */
  background-color: #afd3e6a1;
  color: #000;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  /* box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.eventsPage .cards:hover {
  box-shadow: 0px 4px 12px black;
  cursor: pointer;
  top: -4px;
}

.eventsPage .cards-image {
  position: absolute;
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  border-radius: 8px;
}

.eventsPage .cards-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.eventsPage .cards .status {
  position: absolute;
  top: 15px;
  left: 0px;
  color: white;
  padding: 5px;
  border-radius: 0px 8px 8px 0px;
  width: 85px;
  overflow: hidden;
  transition-duration: 0.5s;
  border: black solid 1px;
}

.eventsPage .cards .status:hover {
  width: 150px;
}

.eventsPage .cards-text {
  text-align: center;
  /* height: 100%; */
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-weight: bold;
}

.eventsPage .cards-text Link {
  text-decoration: none;
}

.eventsPage button:hover {
  background-color: white;
}

.eventsPage .buttons button {
  margin: 5px;
  border-radius: 5px;
  color: black;
  font-weight: bolder;
  transition-duration: 0.2s;
  border: none;
}

.eventsPage .buttons button:hover {
  background-color: rgb(47, 3, 47);
  color: white;
}

/* For single Event Page  */

.eventsPage .card-container {
  display: flex;
  flex-direction: row;
}

.eventsPage .card-image {
  transition-duration: 1s;
}

.eventsPage .card-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.eventsPage .cards img {
  height: 170px;
  width: 190px;
  /* border-radius: 50%; */
}

.eventsPage .cards-text h4 {
  font-family: 'Poppins', sans-serif !important;
  color: #286b9a;
}

.eventsPage .cards-text p {
  color: #163051;
  font-size: 15px;
  font-family: 'Poppins', sans-serif !important;
}

/* For Single Event Gallery Section */

.SingleGallery .my-masonry-grid {
  /* display: -webkit-box; 
  display: -ms-flexbox;  */
  display: flex;
  /* flex-direction: column; */
  margin: 20px auto;
  width: auto;
}

.SingleGallery .image-list {
  display: flex;
  width: 300px;
  margin: 20px 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
}

.SingleGallery .image-list:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  cursor: pointer;
  width: 310px;
}

.SingleGallery .image-list:hover:before {
  transform: scale(2.15);
}

.SingleGallery .image-list img {
  display: flex;
  width: 100%;
  border-radius: 5px;
}

.SingleGallery .my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.SingleGallery.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

/* For Responsiveness */

/* event form */
.eventsPage .eventForm {
  background-color: rgb(231, 227, 232);
  margin: 50px 5vw;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.eventForm h5 {
  font-weight: bold;
}

.eventsPage .form-button {
  padding: 5px;
  background-color: black;
  border-radius: 5px;
  margin: 0px 4px 15px 0px;
}

.eventsPage .form-button:hover {
  background-color: black;
}

.events-banner {
  font-weight: bold;
  position: absolute;
  top: 30%;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
}

@media screen and (max-width: 600px) {

  /* For Events Page  */
  .eventsPage .cards-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .eventsPage .cards {
    margin: 20px;
    width: 60vw;
    height: 60vw;
  }

  .eventsPage .cards-text p {
    color: white !important;
    font-size: 10px;
  }

  .eventsPage .card-container {
    display: flex;
    flex-direction: column;
  }
}