.modal-dialog{
    height: fit-content !important;
}
.modal-content{
    
    border-radius: 6px !important;
}
.modal-footer a{
    background-color: #44699d;
    border: none;
}
.modal-footer a:hover{
    background-color: #3d5e8b;
}
#img-container{
    position: relative !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    height: 45vh !important;
    width: 100% !important;
    border-radius: 6px !important;
    box-sizing: border-box !important;
}
@media (max-width:769px) {
    .modal-dialog{
        width: 75% !important;
    }
}
@media (max-width:576px){
    .modal-dialog{
        margin: auto !important;
        width:78% !important;
    }
    #img-container{
        height: 40vh !important;
    }
}
@media (max-width:426px){
    .modal-dialog{
        margin-top: 10px !important;
    }
    #img-container{
        height: 38vh !important;
    }
}
@media (max-width:376px){
    .modal-dialog{
        width: 85% !important;
    }
    #img-container{
        height: 36vh !important;
    }
}
@media (max-width:329px){
    .modal-dialog{
        width: 92% !important;
    }
    #img-container{
        height: 32vh !important;
    }
}