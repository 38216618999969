.sponsors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sponsors .card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.sponsors .card-body {
  box-shadow: 0 0 8px 2px #5c7494;
  width: 10vw;
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  background-color: #345d97;
  border-radius: 5px;
  padding: 10px;
}

.sponsors .logo {
  height: 90%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.sponsors .logo img {
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 500px) {
  .sponsors .card-list {
    align-items: center;
    justify-content: center;
    width: 90vw;
  }
  .sponsors .card-body {
    height: 10vh;
  }
  .sponsors .sponsor-card{
    width: 100%;
  }
}
