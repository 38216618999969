@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap');

.eventPage .container {
  margin: 5rem;
  align-items: center;
}

.eventPage .cards-container {
  margin: 30px 10px 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.eventPage .card {
  background-color: #cce2eea1;
  color: #0000007a;
  flex: 0 1 35%;
  display: flex;
  flex-direction: column;
  top: 0px;
  position: relative;
  max-width: 262px;
  /* height: 500px; */
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border-radius: 2rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
}

.eventPage .card h3 {
  font-weight: bold;
  color: #286b9a;
}

.eventPage .card p {
  font-size: 16px;
  font-weight: 600;
}

.eventPage .card .eventPage-card-text {
  color: #888;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
  text-align: justify;
}

.eventPage .card:hover {
  transition: all 0.2s ease-out;
  /* box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2); */
  box-shadow: 0 4px 10px rgba(0, 0, 0, .24);
  cursor: pointer;
  /* background-color: white; */
  /* color: black; */
}

.eventPage .loader-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2vw;
}

.eventPage .event-title h1{
  font-family: 'Poppins', sans-serif !important;
}

/* For Responsiveness */

@media screen and (max-width: 992px) {
  .eventPage .cards-container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .eventPage .container {
    margin: 0;
    padding: 20px;
    text-align: center;
  }

  .eventPage .cards-container {
    margin: 0;
  }

  .eventPage .card {
    flex-basis: 85%;
    padding: 4vh 2vh;
    height: auto;
  }
}