 /* .blogsPage .sec-1 {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  
}
.blog-card-container{
  flex:1 1 33.3%;
  height:70vh;
  margin:40px;
  transition: background-color .3s;
  background-color: rgb(43, 3, 91);
  display: flex;
  align-items:center;
  padding:10px;
  
}  */
.blogsPage .sec-1-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:5vh;
  margin-bottom:5vh;
}
.blogsPage .sec-1 {
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 5vh;
  margin-bottom: 5vh;
 
}
.blog-card-container{
  width:30vw;
  margin:25px;
  transition: all .3s;
  background-color: transparent;
  display: flex;
  align-items:center;
  border-radius:12px;
}
.blogsPage .blog-card{
  text-decoration: none;
  box-shadow: none;
  height: 100%;
}


.blog-card-container:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;  
  cursor: pointer;
}

.blogs-banner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0%;
  left: 0%;
  text-align: center;
}



.blog-page .sec-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-page .sec-1 .content {
  width: 80%;
  padding: 50px;
  border-left: 1px solid rgb(207, 207, 207);
  border-right: 1px solid rgb(207, 207, 207);
  margin: 20px;
}

@media only screen and (orientation: portrait), (max-width: 500px) {
  .blogsPage .sec-1 {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 5vh;
    margin-bottom: 5vh;
    
  }
  .blog-card-container {
    display:flex;
    flex-direction:column;
    border-radius: 12px;
    height: 60vh;
    margin:25px;
    width:80vw;
    overflow: hidden;
    align-items: center;
    justify-content: center;

  }
  .blog-card-container img {
    flex: 1;
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 12px;
  }
  .blog-card-container div {
    flex:2;
    overflow-y: auto;
  }

  .blog-page .sec-1 .content {
    width: 90%;
    padding: 0px;
    margin: 4px;
  }

}
