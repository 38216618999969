@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

.flex-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.registration-form {
    background-color: rgba(8, 46, 63, 0.733);
    background-image: url(../../images/aboutUs/formbg.png);
    background-size: cover;
    background-blend-mode: multiply;
    /* opacity: 0.9; */
    color: white;
    border-radius: 20px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.registration-form h5 {
    text-align: center;
    font-size: 38px;
}

.registration-form .comp-rows {
    display: flex;
    justify-content: start;
    align-items: start;

}

.registration-form .labelstyle {
    font-weight: bold;
    font-size: medium;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 7px;
}

/* .registration-form input {
    border-radius: 7.5px;
    border: 1px solid #D9D9D9;
} */

#name,
#email {
    width: 100%;
}

.registration-form sup {
    color: red;
}

.eventFormPage .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventFormPage .btn-container .rule-book-button {
    background-color: #a9cfe5;
    font-weight: bold;
    margin-left: 5%;
    margin-bottom: 10%;
    padding: 1% 5%;
    border-radius: 10px;
    color: white;
    outline: none;
    transition: all 0.3s;
}

.eventFormPage .btn-container .rule-book-button:hover {
    background-color: #9ddbff;
}

.registration-form #submit {
    background-color: #c1dff0;
    font-weight: 400;
    padding: 1% 5%;
    border-radius: 30px;
    font-family: 'Poppins', sans-serif !important;
}

#coupon {
    width: 100%;
}

/* #fee {
    border: 2px solid #1a73e8;
} */

.eventFormPage {
    display: flex;
    flex-direction: column;

}

.eventFormPage section {
    padding: 25px 200px 80px 200px;
}

.eventFormPage .header {
    padding: 80px 200px 0px 200px;
}

.eventFormPage .header .intro {
    display: flex;
    flex-direction: column;
}

.intro h5 {
    font-weight: 700;
    margin-bottom: 25px;
}

.intro p,
.register p {
    line-height: 1.6;
    color: rgb(89, 85, 85);
    font-size: large;
}

.header .cards {
    display: flex;
    justify-content: space-evenly;

}

.header .card {
    margin: 30px 0px 0px 0px;
    width: 300px;
    height: 400px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px;
    background-color: rgb(219, 244, 251);
}

.card .card-image {
    width: 100%;
    height: 50%;
}

.card .card-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card .card-text {
    color: rgb(91, 89, 89);
}

.card .card-text h4 {
    font-weight: 800;
}


.registration .register {
    display: flex;
    flex-direction: column;
}

.registration .form-closed {
    text-align: center;
    color: red;
    font-weight:bold;
}

.register h5 {
    text-align: center;
    /* margin-bottom: 25px; */
    font-weight: bolder;
    font-size: 2rem;
}

.register p {
    padding: 0;
    margin: 0;
}


@media screen and (min-width:0) and (max-width: 400px) {
    .eventFormPage section {
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .header .cards {
        display: grid;
        grid-template-columns: 1fr;

    }

    .registration-form .comp-rows {
        display: flex;
        justify-content: start;
        align-items: start;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .eventFormPage .header {
        padding: 80px 60px 0px 60px;
    }
}

@media screen and (min-width:400px) and (max-width: 1150px) {

    .eventFormPage section {
        padding: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .header .cards {
        display: grid;
        grid-template-columns: 1fr;

    }

    .registration-form .comp-rows {
        display: flex;
        justify-content: start;
        align-items: start;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .eventFormPage .header {
        padding: 80px 60px 0px 60px;
    }

    .registration-form #submit {
        background-color: #c1dff0;
        font-weight: 500;
        padding: 2% 10%;
        border-radius: 30px;
        font-family: 'Poppins', sans-serif !important;
    }

    /* .registration-form .inputwidth{
        width: 100%;
    } */
}

.loading-div {
    background-color: #c1dff0;
    font-weight: 400;
    padding: 1% 5%;
    border-radius: 30px;
    font-family: 'Poppins', sans-serif !important;
    display: flex;
    gap: 8px;
    color: black;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    border-right: 4px solid #3498db;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}