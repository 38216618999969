.banner {
  width: 100vw;
  height: 60vh;
  background: url("../../images/banners/banner-dark-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.up-1{
  transform: translateY(-5px);
}
.aboutPage .sec-1 {
  position: relative;
  padding-top: calc(20vh);
  padding-bottom: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(120deg, #1e2e44 19%, rgb(27, 27, 27) 20%, #2a405f 20%, #2a405f 60%, rgb(27, 27, 27) 60%, #1e2e44 61%, #1e2e44 100% ); */
}

.aboutPage .sec-1 .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(#06214f 1%, transparent 10%);
}

.aboutPage .sec-1 .card-container {
  display: flex;
  margin: 20px;
  margin-bottom: 40px;
  width: 80vw;
  height: 40vh;
  box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(150, 206, 255);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

.aboutPage .sec-1 .img-container {
  display: flex;
  overflow: hidden;
  width: 40%;
}

.aboutPage .sec-1 .img-container img {
  object-fit: cover;
  width: 100%;
}

.aboutPage .sec-1 .text-container {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  color: white;
}

.aboutPage .sec-2 {
  display: flex;
  padding: 10vh;
  margin: 0;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-color: #0a0b41;
}

.aboutPage .sec-2 .text-container {
  padding: 30px;
  color: white;
  font-weight: bold;
}

.aboutPage .sec-2 .text-container h4 {
  color: rgb(255, 204, 0);
}

.aboutPage .sec-2 .text-container h1,
.aboutPage .sec-2 .text-container h4 {
  font-weight: 800;
}

.aboutPage .sec-2 .img-container {
  flex: 1;
  display: flex;
  height: 70vh;
  width: 70vh;
  overflow: hidden;
  box-shadow: 20px 20px rgb(150, 206, 255);
  margin-left: 5vw;
}

.aboutPage .sec-2 .img-container img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 70vh;
  box-shadow: 0 0 5px 2px white;
}

.aboutUs-banner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 30%;
  text-align: center;
  font-weight: bold;
}

.aboutPage > .sec-2 > div {
  flex: 1;
}

.aboutPage > .sec-2 > .text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 30px;
  justify-content: center;
}

.aboutPage .sec-3 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding: 10vw;
  padding-bottom: 0vw;
  margin-bottom: 0px;
  background: linear-gradient(350deg, rgb(150, 206, 255) 30%, white 30%);
}

.aboutPage .sec-3 .text-container {
  flex: 1;
}

.aboutPage .sec-3 .img-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.aboutPage .sec-3 .img-container img {
  object-fit: contain;
  object-position: bottom;
}

.aboutPage .sec-4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(190deg, rgb(150, 206, 255) 60%, white 60%);
  padding-top: 5vw;
}

.aboutPage .sec-4 .img-slider {
  width: 100%;
  height: 100%;
}
.aboutPage .sec-4 h2 {
  font-weight: bold;
}

.aboutPage .sec-4 h6 {
  width: 60vw;
  font-size: large;
}

.aboutPage .sec-5 {
  margin-top: 80px;
  padding-top: 40px;
  background-color: #2a405f;
  color: white;
  padding-bottom: 80px;
}

.aboutPage .sec-5 .images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutPage .sec-5 .images .item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 14px;
}

.aboutPage .sec-5 .images .img-container {
  height: 40vh;
  width: 40vh;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
}

.aboutPage .sec-5 .images .img-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.aboutPage .sec-5 .images .text-container {
  color: white;
  text-align: center;
  align-items: center;
}

.aboutPage .sec {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.aboutPage .sec5 {
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: #0a0b41;
  color: white;
}

.aboutPage .sec5_body {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aboutPage .sec5_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  flex: 1;
}

.aboutPage .imageContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}

.aboutPage .sec-6 {
  margin-top: 0px;
}
.aboutPage .sec-6 {
  padding-top: 40px;
  background-color: #778aa5;
  color: white;
  padding-bottom: 80px;
}

.aboutPage .sec-6 .images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutPage .sec-6 .images .item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 14px;
}

.aboutPage .sec-6 .images .img-container {
  height: 40vh;
  width: 40vh;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 10px;
}

.aboutPage .sec-6 .images .img-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.aboutPage .sec-6 .images .text-container {
  color: white;
  text-align: center;
  align-items: center;
}

@media only screen and (orientation: portrait), (max-width: 768px) {
  .aboutPage .sec-1 {
    flex-direction: column;
  }

  .aboutPage .sec-1 .card-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 20px;
    margin-bottom: 40px;
    width: 90vw;
    box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.1);
    border: 1px solid rgb(150, 206, 255);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
  }

  .aboutPage .sec-1 .card-container .text-container,
  .aboutPage .sec-1 .card-container .text-container h2 {
    align-items: center;
    text-align: center;
    padding: 5px;
    padding-top: 10px;
  }

  .aboutPage .sec-1 .card-container .text-container p {
    color: white !important;
  }

  .aboutPage .sec-1 .img-container {
    width: 100%;
    height: 25vh;
  }
  .aboutPage .sec-2 {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    padding-top: 10vh;
  }

  .aboutPage .sec-2 .img-container {
    width: 80vw;
    height: 60vw;
    box-shadow: 0 0 8px 2px rgb(150, 206, 255);
    animation-iteration-count: infinite;
    animation-name: twinkle;
    animation-duration: 1.8s;
  }

  .aboutPage .sec-2 .img-container img {
    object-fit: contain;
  }

  .aboutPage .sec-2 .text-container,
  .aboutPage .sec-2 .text-container p {
    align-items: center;
    text-align: center;
    color: white !important;
    padding: 15px 5px;
  }

  .aboutPage > h2 {
    font-size: 5vh;
  }

  .aboutPage .sec-3 {
    padding: inherit;
    padding-top: 20vh;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border: none;
  }
  .aboutPage .sec-3 .img-container {
    width: 70vw;
  }
  .aboutPage .sec-3 .text-container {
    text-align: center;
  }

  .aboutPage .sec-4 {
    padding-top: 10vh;
    padding-bottom: 10vh;
    border: none;
  }

  .aboutPage .sec-4 h6 {
    width: 90vw;
  }

  .aboutPage .sec-5 {
    margin-top: 80px;
  }

  .aboutPage .sec-5 .images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
  }

  .aboutPage .sec-5 .images div img {
    height: 40vh;
  }

  .aboutPage .sec-6 {
    margin-top: 0px;
  }

  .aboutPage .sec-6 .images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
  }

  .aboutPage .sec-6 .images div img {
    height: 40vh;
  }

  @keyframes hover-animation {
    0% {
      transform: translate(-10px, 5px);
    }
    50% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(-10px, 5px);
    }
  }

  @keyframes twinkle {
    0% {
      box-shadow: 0 0 4px 2px white;
    }
    50% {
      box-shadow: 0 0 3px 1px rgb(150, 206, 255);
    }
    100% {
      box-shadow: 0 0 4px 2px white;
    }
  }
}
