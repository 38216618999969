.ourTeamsPage .sec-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10vh 10vw;
  border-radius: 10px;
  box-shadow: 0 0 10px 4px rgb(199, 199, 199);
  overflow: hidden;
  padding: 15px;
  font-size: 1.3vw;
}

.ourTeamsPage .sec-1 div {
  flex: 1;
  display: flex;
  flex-direction: column;

}

.ourTeamsPage .sec-1 img {
  object-fit: cover;
  width: 100%;

}


.ourTeamsPage .text-container,
.teamPage .text-container {
  text-align: left;
  padding-left: 20px;
}

.ourTeamsPage .sec-2 {
  margin-top: 20vh;
  padding:10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #44699d;
}

.ourTeamsPage .sec-2 h6 {
  width: 70%;
}


.ourTeamsPage .sec-2 .images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ourTeamsPage .images .image-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 25vw;
  margin: 10px 20px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;

}

.ourTeamsPage .sec-2 img {
  object-fit: contain;
  max-width: 100%;

}

.ourTeamsPage .images .image-container:hover {
  cursor: pointer;
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}


.ourTeamsPage .sec-2 .team-name {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1px 4px;
  position: absolute;
  bottom: 5px;
  left: 0;
}


.ourTeamsPage .images .overlay {
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index: 100;
  margin: 0;
  background: transparent;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.2);

}


.ourTeamsPage .images .overlay:hover {
  background-color: transparent;
}

.teamPage .sec-1 {
  display: flex;
  flex-direction: row;
  margin: 50px 5vw;
}

.teamPage .sec-1 div {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.teamPage .sec-1 img {
  object-fit: cover;
  width: 100%;
  position: relative;
  animation-name: hoverAnimation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
}

.teamPage .sec-1 div {
  display: flex;
  justify-content: center;
}

.teamPage .sec-1 .text-container {
  padding-left: 50px;
  font-size: 1.3vw;
}

.teamPage .sec-2 {
  margin: 20vh 0vw;
  background-color: #44699d;
  color: white;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.teamPage .sec-2 h6 {
  width: 60%;
  text-align: center;
}

.teamPage .sec-2 .images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}


.teamPage .sec-2 .image {
  margin:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #273c58;
  color: white;
  width: 18vw;
  border: 0.1px solid #273c58;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 8px 4px rgba(255, 255, 255, 0.5);
 
}

.teamPage .sec-2 .image img {
  object-fit: cover;
  height: 25vw;
  width: 100%;
}

.teamPage .sec-2 .image p {
  color: white !important;
  text-align: center;
  margin: 4px;
}

.eventsPage {
  background-color: rgb(252, 252, 252);
}

.eventsPage .sec-1 {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  width:100vw;
}

.eventsPage .eventsListContainer {
  width:70%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(240px, 1fr));
  align-items:center;
  justify-items: center;
}

/*Below is the card styling for each event in eventsList*/
.eventsPage .eventsListContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  width: 250px;
  height: 340px;
  margin: 15px;
  padding:5px;
  flex-wrap: wrap;
  background-color:rgb(255, 255, 255);
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.644);
  transition: 0.4s;
  cursor: pointer;
}

.eventsPage .eventsListContainer > div:hover {
  background-color:rgb(255, 255, 255);
  box-shadow: 0 0 8px 5px rgba(112, 112, 112, 0.664);
  width: 255px;
  height: 345px;
  margin: 10px; 
  /* Added 5px to dimensions and subtracted 5px 
  from margin to remove shifting of other cards*/
}

.eventsPage .eventsListContainer > div > div {
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  height: 10vw;
}




@keyframes hoverAnimation {
  0% {
    transform: translate(0%);
  }
  50% {
    transform: translate(2%, 1%);
  }
  100% {
    transform: translate(0%);
  }
}

@media only screen and (orientation: portrait), (max-width: 500px) {
  .banner {
    height: 40vh;
  }
  

  .ourTeamsPage .sec-1  {
    flex-direction: column;
    margin: 10vh 5vw 5vw 5vw;
    border-bottom: 1px solid rgb(165, 164, 164);
  }

  .ourTeamsPage .text-container,
  .teamsPage .text-container {
    padding: 0 10px;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
  }

  .ourTeamsPage .sec-2 {
    margin-top: 50px;
  }
  .ourTeamsPage .sec-2 h6 {
    width: 100%;
    padding: 5px 2vw;
  }
  .ourTeamsPage .sec-2 .images {
    flex-direction: column;
  }
  .ourTeamsPage .images .image-container {
    width: 90vw;
    box-shadow: 0 0 12px 2px rgba(255, 255, 255, 0.5);
  }
  .ourTeamsPage .images .image-container .overlay{
    background-color: transparent;
  }

  .teamPage .sec-1 {
    flex-direction: column;
    border-bottom: 1px solid gray;
  }
  .teamPage .sec-1 .text-container{
    padding: 0;
    margin-top: 25px;
    font-size: 16px;
  }

  .teamPage .sec-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .teamPage .sec-2 h6 {
    width: 80%;
    text-align: center;
  }
  
  .teamPage .sec-2 .images {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5vw;
  }
  .teamPage .sec-2 .image {
    width: 40vw;
    margin: 2vw;
  }

  .teamPage .sec-2 .image img {
    object-fit: cover;
    height: 20vh;
  }

  .teamPage .sec-2 .image p {
    color: white;
    text-align: center;
  }

  
}
