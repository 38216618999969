 .blogs-sec {
  background-image: url("../../../images/Blog\ Background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blogs-sec h2 {
  color: white;
  font-weight: bold;
}


.blogs-sec .list-container {
  width: 80vw;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-top: 10px;
} 

.blogs-sec .blog-card-container {
  width: 22vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 10px;
  margin: 0px 10px;
  transition: all 0.3s;
}

.blogs-sec .blog-card {
  flex-grow: 1;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  overflow: hidden;
}


.blogs-sec  .blog-card:hover .blog-card-img {
  transform: scale(1.05);
}

.blogs-sec .blog-card p{
  padding-bottom: 5px;
}

@media only screen and (orientation: portrait), (max-width: 500px) {

  .blogs-sec .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }

  .blogs-sec .blog-card-container {
    width: 80vw;
    height: 60vh;
    margin-bottom: 20px;
  }

}


