.banner {
  width: 100vw;
  height: 40vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-title {
  text-align: center;
  font-weight: bold;
  color:black;
}

@media only screen and (orientation: portrait), (max-width: 500px) {
  .banner {
    height: 40vh;
    background-position: inherit;
    background-repeat: no-repeat;
  }
}
