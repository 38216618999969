.imgSlide{
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.slick-slide > div{
  margin: 0 10px;
  object-fit: cover;
}

.slick-list{
  margin: 0 -10px;
}

.slick-next:before {
  font-size: 40px;
  border-radius: 50%;
  opacity: .75;
  color: rgb(0, 0, 0);
  position: absolute;
  top:-10px;
  right: 40px;
}

.slick-prev {
  z-index: 1000;
}

.slick-prev:before {
  font-size: 40px;
  border-radius: 50%;
  opacity: .75;
  color: rgb(0, 0, 0);
  position: absolute;
  top:-10px;
  left: 40px;
}

.img-slider{
  height: 50vh;
  color: #fff;
}

.img-slidercard-top{
  height: 50vh;
}

.img-slidercard-top > img{
  width: 100%;
  object-fit: cover;
}

/* .Slide{
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.slick-slide > div{
  margin: 0 10px;
}

.slick-list{
  margin: 0 -10px;
}

.slick-next:before {
  font-size: 40px;
  border-radius: 50%;
  opacity: .75;
  color: white;
  position: absolute;
  top:-10px;
  right: 40px;
}

.slick-prev {
  z-index: 1000;
}

.slick-prev:before {
  font-size: 40px;
  border-radius: 50%;
  opacity: .75;
  color: white;
  position: absolute;
  top:-10px;
  left: 40px;
}

.card{
  overflow: hidden;

  color: #fff;
}

.card-top > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
} */